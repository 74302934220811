<template>
<div class="flex items-center gap-4">
    <div
        v-if="item.email_delivery_status === false"
        class="bounceEmailCheck"
    >
        <component :is="icons['bounceEmail']" />
        <span>Bounce Back: {{ item.email_status }}</span>
    </div>

    <div v-if="allowedActions.length > 0" class="flex gap-2 max-h-10">
        <template v-for="btn in allowedActions">
            <div
                v-if="showAction(btn)"
                :key="buttons[btn].id"
                :name="`${item.id}__action-item__${buttons[btn].id}`"
                class="flex align-center"
                @click.stop.prevent="handleAction(buttons[btn])"
            >
                <component
                    :is="buttons[btn].component"
                    v-bind="buttons[btn].props"
                    v-on="buttons[btn].events"
                    :title="buttons[btn].title"
                    :class="[buttons[btn].class, 'flex align-center']"
                    :is-ack="isAcknowledge(item)"
                    :item="item"
                />
                <component
                    v-if="buttons[btn].modal"
                    :is="buttons[btn].modal"
                    :type="buttons[btn].type"
                    :item="item"
                    :isSequenceModalOpen="isSequenceModalOpen"
                    :to="`${item.id}__action-item__${buttons[btn].id}`"
                    @closeSequenceModal="isSequenceModalOpen = false"
                />
            </div>
        </template>
    </div>
</div>
</template>

<script>
import { buttons } from "@/components/dashboard-components/checklist/utils";
import { EventBus } from "@/main.js";
const emailIcon = () => import("@/assets/icons/dashboard/activity/email.svg");
const bounceBackEmailIcon = () => import("@/assets/icons/dashboard/activity/bounce-back-email.svg");

const ALLOWED_ACTIONS = ["expandSequenceBadge"];

export default {
    name: "EmailScheduler",

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSequenceModalOpen: false,
        }
    },

    computed: {
        buttons() {
            return buttons;
        },
        icons() {
            return {
                email: emailIcon,
                bounceEmail: bounceBackEmailIcon,
            };
        },
        allowedActions() {
            return this.item?.actions?.filter(action => ALLOWED_ACTIONS.includes(action)) ?? [];
        },
    },

    methods: {
        isAcknowledge(item) {
            return (item.activity_type === 'email' || item.activity_type === 'email_sequence') ? item.is_ack : item.acknowledged;
        },
        handleAction(e) {
          if (e.action && this.item?.templates?.length) this[e.action] = true;
          else 
           EventBus.$emit('activityAction', {type: e.type, data: this.item});
        },
        showAction(btn) {
            return btn in this.buttons;
        }
    },
}
</script>

<style lang="scss" scoped>
.bounceEmailCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 378px;
    padding: 10px;
    background-color: #F6F6F6;

    span {
        margin-left: 10px;
        font-size: 14px;
        color: #FF7777;
    }

    svg {
        width: 20px;
    }
}
</style>